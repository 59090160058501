/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
/* eslint-disable no-undef */

exports.onRouteUpdate = ({ location }) => {
    if (typeof window.gtag !== "undefined") {
      gtag("event", "page_view", {
        page_location: location.href,
        page_path: location.pathname,
        page_title: window.document.title,
      });
    }
  }
  